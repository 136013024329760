<template>
  <div>
    <div
      class="relative"
      :class="[
        'SweetMark',
        {
          'has-exceeded-limit': remaining <= 0,
        },
        isFocused ? 'focused' : '',
      ]"
    >
      <quillEditor
        v-if="isReady"
        ref="note"
        class="note"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        :options="options"
        @keydown.ctrl.enter="onKeydown"
        v-model="content"
      />
      <textarea
        placeholder="Add notes.."
        v-else
        class="
          block
          w-full
          h-12
          px-4
          py-1
          leading-normal
          bg-white
          border border-gray-400
          rounded
          outline-none
          focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500
        "
        @focus="onReady(true)"
      ></textarea>
      <div class="SweetMark__limit">
        <span class="SweetMark__remainingCharacters">
          {{ remaining }}
        </span>
        <svg
          class="`SweetMark__counter`"
          viewBox="0 0 33.83098862 33.83098862"
          height="20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="SweetMark__counterUnderlay"
            cx="16.91549431"
            cy="16.91549431"
            r="15.91549431"
            fill="none"
            stroke-width="2"
          />
          <circle
            class="SweetMark__counterProgress"
            :stroke-dasharray="`${ratio},100`"
            cx="16.91549431"
            cy="16.91549431"
            r="15.91549431"
            fill="none"
            stroke-width="4"
          />
        </svg>
      </div>
    </div>
    <div v-if="isReady" class="flex items-center gap-2 justify-end mt-4">
      <button
        class="
          border-0
          rounded-md
          bg-gray-300
          text-gray-900
          px-3
          py-1
          hover:opacity-90
          transition-all
          font-semibold
          ease-linear
        "
        @click="onReady(false)"
      >
        Cancel
      </button>
      <button
        class="
          border-0
          rounded-md
          bg-green-500
          text-white
          px-3
          py-1
          hover:opacity-90
          font-semibold
          transition-all
          ease-linear
        "
        @click="onSave"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 140,
    },
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      options: {
        placeholder: 'Take a note...',
        readOnly: true,
        theme: 'snow',
      },
      textLen: 0,
      remaining: 0,
      ratio: 0,
      isFocused: false,
      isReady: false,
      content: '',
    }
  },
  mounted() {
    this.textLen = 0
    this.remaining = this.limit
  },
  methods: {
    remainingCharacters() {
      let text = this.$refs.note.quill.getText()
      this.textLen = text.length
      this.remaining = this.limit - this.textLen
    },
    limitStatus() {
      this.ratio = (this.textLen / this.limit) * 100
    },
    onFocus() {
      this.isFocused = true
      this.changeStatus(true)
    },
    onReady(flag) {
      this.content = ''
      this.isReady = flag
      this.isFocused = flag
    },
    changeStatus(flag) {
      if (flag) {
        this.isFocused = true
      }
      this.$refs.note.quill.enable(flag)
    },
    onInput() {
      if (this.textLen < this.limit) {
        this.remainingCharacters()
        this.limitStatus()
        this.$emit(`input`, this.content)
      } else {
        this.changeStatus(false)
      }
    },
    onBlur() {},
    onKeydown() {
      this.onSave()
    },

    onSave() {
      this.$emit('save')
      this.content = ''
    },
  },
}
</script>

<style lang="scss">
.SweetMark {
  $color-border: #657786;
  $color-danger: #e0245e;
  $color-danger-light: #ffb8c2;
  $color-gray: #657786;
  $color-gray-light: #ccd6dd;
  $color-primary: #1da1f2;
  position: relative;

  &__limit {
    display: flex;
    position: absolute;
    right: 0.75em;
    bottom: 0.75em;
    align-items: center;
  }
  &__remainingCharacters {
    margin-right: 0.5em;
    color: $color-gray;
    font-size: 0.75em;
    .has-exceeded-limit & {
      color: $color-danger;
    }
  }
  // 1. Making overflowing content visible, because
  //    otherwise the `counterPulse` animation would be
  //    cut off.
  &__counter {
    overflow: visible; // 1
    transform: rotate(-90deg);
    transform-origin: center;
  }
  &__counterUnderlay {
    stroke: $color-gray-light;
  }
  &__counterProgress {
    stroke: $color-primary;
    .has-exceeded-limit & {
      stroke: $color-danger;
      animation: counterPulse 0.3s ease-in-out;
      animation-iteration-count: 1;
    }
  }
  @keyframes counterPulse {
    0% {
      stroke-width: 4;
    }
    50% {
      stroke-width: 6;
    }
    100% {
      stroke-width: 4;
    }
  }
}

.qu-container {
  transition: all 500ms ease-in-out;
}
.focused .ql-container:focus-within {
  border-top: 1px solid !important;
}
.focused .ql-container {
  border-top: 1px solid !important;
  border-color: #2c2a2a;
  min-height: 80px !important;
}
</style>
